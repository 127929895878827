import { LoadingButton } from "@mui/lab";
import { Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReportKind, ReportSource } from "../../api/biApi.types";
import { updateTrigger } from "../../store/reportsSlice";
import { selectSelectedCompany, selectUserInfo } from "../../store/userSlice";
import ExcelSheetsDropdown from "./ExcelSheetsDropdown";
import { XEntriliaReportPrefix } from "./hooks/useEntriliaReportsMonitoring.types";
import { useExcelDataApi } from "./hooks/useExcelDataApi";
import { XDimension, XWorksheetData } from "./hooks/useExcelDataApi.types";
import { iterateOverAllLevelGroups } from "./utils/loadGroups";
import { IMAGE_NAME_PREFIX } from "./utils/loadImages";
import populateWorksheet from "./utils/populateWorksheet";
import { addReportSetting, generateReportId, getStoredReportSettings } from "./utils/storedReportSettings";
import XRange from "./XRange";

type LocalExcelSheetNames = {
  legp: string;
  test: string;
};

export default function DevTools() {
  const legpPathIdx = 0;
  const testPathIdx = 1;
  const dispatch = useDispatch();
  const selectedCompany = useSelector(selectSelectedCompany);
  const [selectedWorksheetName, setSelectedWorksheetName] = React.useState<string | null>("D - TB");
  const [activeWorksheetId, setActiveWorksheetId] = React.useState<string>();
  const [loadingState, setLoadingState] = React.useState("idle");
  const [selectedLocalExcelSheetNames, setSelectedLocalExcelSheetNames] = React.useState<LocalExcelSheetNames>({
    legp: "",
    test: "",
  });
  const {
    data,
    loading,
    load,
    loadSheetNamesFromLocalFile,
    legpSheetNames,
    setLegpSheetNames,
    loadSheetDataFromLocalFile,
    dataLocal,
    setTestFileSheetNames,
    testFileSheetNames,
  } = useExcelDataApi();
  const userInfo = useSelector(selectUserInfo);

  const handleLoadData = React.useCallback(async () => {
    if (selectedWorksheetName !== null) {
      const worksheetId = await getActiveWorksheetId();
      setActiveWorksheetId(worksheetId);

      await load(selectedWorksheetName);
    }
  }, [load, selectedWorksheetName]);

  const handleLoadedReport = React.useCallback(
    async (data: XWorksheetData, worksheetName: string | null) => {
      if (activeWorksheetId === undefined) return;

      setLoadingState("preparing report");
      await Excel.run({ delayForCellEdit: true }, async (context) => {
        setLoadingState("checking reporting range...");
        await expandRangeForNewReportAndCleanArea(context, activeWorksheetId, data.dimension);

        setLoadingState("handle old report references...");
        await removeNamedRange(context, activeWorksheetId);

        setLoadingState("populate data to the worksheet...");
        await populateWorksheet(context, data, activeWorksheetId);

        setLoadingState("saving report...");
        await addReportSetting(
          generateReportId(),
          context,
          activeWorksheetId,
          data.dimension.reference,
          worksheetName || "E. Cash",
          "",
          [],
          selectedCompany || "unknown",
          "ReportType",
          false,
          ReportSource.BC,
          ReportKind.Report,
          [],
          userInfo?.userName
        );
      });
      dispatch(updateTrigger());
      setLoadingState("idle");
    },
    [activeWorksheetId, dispatch, userInfo?.userName, selectedCompany]
  );

  const handleLocalExcelLoad = React.useCallback(
    async (path: number, sheetName: string) => {
      if (selectedLocalExcelSheetNames) {
        setActiveWorksheetId(await getActiveWorksheetId());
        loadSheetDataFromLocalFile(path, sheetName);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedLocalExcelSheetNames]
  );

  React.useEffect(() => {
    if (data !== undefined) {
      handleLoadedReport(data, selectedWorksheetName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (dataLocal) {
      handleLoadedReport(dataLocal[0], dataLocal[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLocal]);

  React.useEffect(() => {
    if (!legpSheetNames.length) {
      loadSheetNamesFromLocalFile(legpPathIdx, setLegpSheetNames);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legpSheetNames]);

  React.useEffect(() => {
    if (!testFileSheetNames.length) {
      loadSheetNamesFromLocalFile(testPathIdx, setTestFileSheetNames);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testFileSheetNames]);

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={4}>
          <TextField
            size="medium"
            label="Worksheet Name"
            value={selectedWorksheetName}
            disabled={loading}
            onChange={(evt) => setSelectedWorksheetName(evt.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          {legpSheetNames.length > 0 && (
            <ExcelSheetsDropdown
              id={"LEGP"}
              sheets={legpSheetNames}
              onChange={(n) => setSelectedLocalExcelSheetNames((prev) => ({ ...prev, legp: n }))}
              label="LEGP"
            />
          )}
        </Grid>
        <Grid item xs={4}>
          {testFileSheetNames.length > 0 && (
            <ExcelSheetsDropdown
              id={"Test"}
              sheets={testFileSheetNames}
              onChange={(n) => setSelectedLocalExcelSheetNames((prev) => ({ ...prev, test: n }))}
              label="Test"
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <LoadingButton size="small" variant="contained" loading={loading} disabled={loading} onClick={handleLoadData}>
            Load from Excel
          </LoadingButton>
        </Grid>
        <Grid item xs={4}>
          {legpSheetNames.length > 0 && (
            <LoadingButton
              size="small"
              variant="contained"
              loading={loading}
              disabled={loading}
              onClick={() => handleLocalExcelLoad(legpPathIdx, selectedLocalExcelSheetNames.legp)}
            >
              Load LEGP
            </LoadingButton>
          )}
        </Grid>
        <Grid item xs={4}>
          {testFileSheetNames.length > 0 && (
            <LoadingButton
              size="small"
              variant="contained"
              loading={loading}
              disabled={loading}
              onClick={() => handleLocalExcelLoad(testPathIdx, selectedLocalExcelSheetNames.test)}
            >
              Load from Test
            </LoadingButton>
          )}
        </Grid>
      </Grid>
      <Typography>{loading ? "loading report" : loadingState}</Typography>
    </>
  );
}

export async function removeNamedRange(context: Excel.RequestContext, worksheetId: string) {
  //remove Entrilia report from active worksheet names
  const sheet = context.workbook.worksheets.getItem(worksheetId);
  context.load(sheet, "items/names/name");
  await context.sync();

  sheet.names.items.forEach((name) => {
    if (name.name.startsWith(XEntriliaReportPrefix)) {
      name.delete();
    }
  });

  await context.sync();
}

export async function expandRangeForNewReportAndCleanArea(
  context: Excel.RequestContext,
  worksheetId: string,
  dimension: XDimension
) {
  const worksheet = context.workbook.worksheets.getItem(worksheetId);
  context.load(worksheet, "items/names/name");
  await context.sync();

  const namedRange = worksheet.names.items.find((n) => n.name.startsWith(XEntriliaReportPrefix));
  if (namedRange === undefined) return;
  const storedReports = await getStoredReportSettings(context);
  const storedReport = storedReports.find((r) => r.worksheetId === worksheetId && r.id === namedRange.name);
  if (storedReport === undefined) return;

  const { columnsRangeAddress, rowsRangeAddress } = calculateNonIntersectingRanges(
    storedReport.address,
    dimension.reference
  );

  //move columns range to the right
  if (columnsRangeAddress !== undefined) {
    await shiftColumnsToTheRight(context, worksheet, columnsRangeAddress);
  }

  if (rowsRangeAddress !== undefined) {
    await shiftRowsToTheBottom(context, worksheet, rowsRangeAddress);
  }

  await deleteTables(worksheet, context);
  await deleteShapes(worksheet, context);

  //clean the area with the old report
  const rangeToClean = worksheet.getRange(storedReport.originalAddress);
  removeGroups(rangeToClean);
  rangeToClean.unmerge();
  rangeToClean.clear("All");

  await context.sync();
}

export const removeGroups = (range: Excel.Range) => {
  iterateOverAllLevelGroups(() => {
    range.ungroup(Excel.GroupOption.byRows);
    range.ungroup(Excel.GroupOption.byColumns);
  });
};

export async function deleteTables(worksheet: Excel.Worksheet, context: Excel.RequestContext) {
  const tables = worksheet.tables;
  tables.load("items/name");
  await context.sync();
  tables.items.forEach((table) => {
    if (table.name.startsWith(TABLE_NAME_PREFIX)) {
      table.delete();
    }
  });
}
export async function deleteShapes(worksheet: Excel.Worksheet, context: Excel.RequestContext) {
  const shapes = worksheet.shapes;
  shapes.load("items/name");
  await context.sync();
  shapes.items.forEach((shape) => {
    if (shape.name.startsWith(IMAGE_NAME_PREFIX)) {
      shape.delete();
    }
  });
}

async function shiftColumnsToTheRight(
  context: Excel.RequestContext,
  worksheet: Excel.Worksheet,
  deltaRangeAddress: string
) {
  const rangeToMove = worksheet.getRange(deltaRangeAddress);
  rangeToMove.getEntireColumn().insert(Excel.InsertShiftDirection.right);
  await context.sync();
}

function shiftRowsToTheBottom(context: Excel.RequestContext, worksheet: Excel.Worksheet, rowsRangeAddress: string) {
  const rangeToMove = worksheet.getRange(rowsRangeAddress);
  rangeToMove.getEntireRow().insert(Excel.InsertShiftDirection.down);
  return context.sync();
}

async function getActiveWorksheetId() {
  return await Excel.run({ delayForCellEdit: true }, async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    context.load(sheet, "id");
    await context.sync();
    return sheet.id;
  });
}

function calculateNonIntersectingRanges(range1Address: string, range2Address: string) {
  const range1 = new XRange(range1Address);
  const range2 = new XRange(range2Address);

  const columnsRangeAddress = range1.nonIntersectingColumns(range2);
  const rowsRangeAddress = range1.nonIntersectingRows(range2);

  return { columnsRangeAddress, rowsRangeAddress };
}

//Excel table has restrictions on table name
//https://support.microsoft.com/en-us/office/rename-an-excel-table-fbf49a4f-82a3-43eb-8ba2-44d21233b114
const TABLE_NAME_PREFIX = "Ent_ilia";
