import { Alert, Box } from "@mui/material";

const CellEditModeAlert = () => {
  return (
    <Box p={2} zIndex={10000} position={"absolute"} bottom={25} left={0} right={0}>
      <Alert severity="warning">
        Actions cannot be performed while cells are being edited. Once editing is complete, tasks will resume
        automatically
      </Alert>
    </Box>
  );
};

export default CellEditModeAlert;
