import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Avatar } from "@mui/material";
import ClientIcon from "../selectClient/ClientIcon";
interface Props {
  isReadonly: boolean;
  isDeleted: boolean;
  reportCompanyLogo: string | undefined;
}

const WorkbookReportItemIcon = ({ isReadonly, isDeleted, reportCompanyLogo }: Props) => {
  if (isReadonly) {
    return (
      <Avatar variant="rounded" sx={{ width: 24, height: 24, bgcolor: "#EEEFF0" }}>
        <LockOutlinedIcon color="disabled" fontSize="small" />
      </Avatar>
    );
  }

  if (isDeleted) {
    return (
      <Avatar variant="rounded" sx={{ width: 24, height: 24, bgcolor: "#EEEFF0" }}>
        <DeleteOutlineRoundedIcon color="action" fontSize="small" />
      </Avatar>
    );
  }

  return <ClientIcon logoUrl={reportCompanyLogo} />;
};

export default WorkbookReportItemIcon;
