import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { logError } from "../../../logging";
import { setIsExecutingExcelRun } from "../../../store/workbookSlice";

const useExcelRunExecution = () => {
  const dispatch = useDispatch();

  const excelRun = useCallback(async (batch: (ctx: Excel.RequestContext) => Promise<void>) => {
    try {
      dispatch(setIsExecutingExcelRun(true));
      await Excel.run({ delayForCellEdit: true }, batch);
    } catch (error) {
      logError(error, "excelRun");
    } finally {
      dispatch(setIsExecutingExcelRun(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { excelRun };
};

export default useExcelRunExecution;
