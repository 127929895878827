import { PropsWithChildren } from "react";
import { ExcelReportParameterDescription, ReportSource } from "../../api/biApi.types";
import withParametersValidation from "./hocs/withParametersValidation";
import SetParametersStep, { SetParametersStepProps } from "./SetParametersStep";

interface Props extends SetParametersStepProps {
  parameters: ExcelReportParameterDescription[];
  reportId: string | undefined;
}

const SetParametersStepWithParametersValidation = withParametersValidation(SetParametersStep);

const SetParametersStepWrapper = (props: PropsWithChildren<Props>) => {
  return props.report.reportSource === ReportSource.BI ? (
    <SetParametersStepWithParametersValidation {...props} />
  ) : (
    <SetParametersStep {...props} />
  );
};

export default SetParametersStepWrapper;
