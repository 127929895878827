import { XImageShape } from "../hooks/useExcelDataApi.types";

const maxSizeKb = 3072;

export async function loadImages(context: Excel.RequestContext, sheet: Excel.Worksheet, images: XImageShape[]) {
  const rangesMap = await loadImageRanges(context, sheet, images);
  if (!rangesMap) {
    return;
  }

  let accumulatedSizeKb = 0;

  for (let i = 0; i < images.length; i++) {
    const imageData = images[i];
    if (!imageData) continue;

    if (isPayloadSizeExceeded(accumulatedSizeKb, imageData.sizeKb)) {
      // eslint-disable-next-line office-addins/no-context-sync-in-loop
      await context.sync();
      accumulatedSizeKb = 0;
    }

    setupImage(imageData, rangesMap, sheet);
    accumulatedSizeKb += imageData.sizeKb;
  }

  if (accumulatedSizeKb > 0) {
    await context.sync();
  }
}

async function loadImageRanges(context: Excel.RequestContext, sheet: Excel.Worksheet, images: XImageShape[]) {
  const rangesMap = new Map<string, RangeData>();

  context.application.suspendApiCalculationUntilNextSync();
  context.application.suspendScreenUpdatingUntilNextSync();
  let hasSynced = true;
  let unSyncedIterations = 0;

  for (let i = 0; i < images.length; i++) {
    const imageData = images[i];
    if (!imageData) {
      return;
    }
    unSyncedIterations++;
    hasSynced = false;
    loadRangeAreaForImage(imageData, sheet, rangesMap);

    if (unSyncedIterations > 0 && unSyncedIterations % 3000 === 0) {
      // eslint-disable-next-line office-addins/no-context-sync-in-loop
      await context.sync();
      hasSynced = true;
      context.application.suspendApiCalculationUntilNextSync();
      context.application.suspendScreenUpdatingUntilNextSync();
    }
  }

  if (!hasSynced) {
    await context.sync();
  }

  return rangesMap;
}

function isPayloadSizeExceeded(accumulatedSize: number, currentImageSize: number) {
  return accumulatedSize + currentImageSize > maxSizeKb;
}

function setupImage(imageData: XImageShape, rangesMap: Map<string, RangeData>, sheet: Excel.Worksheet) {
  const rangeFromMap = rangesMap.get(imageData.name);
  if (!rangeFromMap) return;
  const totalHeight = rangeFromMap.entireRange.height;
  const totalWidth = rangeFromMap.entireRange.width;
  const shape = sheet.shapes.addImage(imageData.imageBase64);

  shape.top = rangeFromMap.entireRange.top + imageData.from.rowOff;
  shape.left = rangeFromMap.entireRange.left + imageData.from.colOff;
  shape.width = Math.min(
    totalWidth,
    totalWidth - rangeFromMap.bottomRightRange.width + imageData.to.colOff - imageData.from.colOff
  );
  shape.height = Math.min(
    totalHeight,
    totalHeight - rangeFromMap.bottomRightRange.height + imageData.to.rowOff - imageData.from.rowOff
  );
  shape.name = `${IMAGE_NAME_PREFIX}_${imageData.name}`;
}

function loadRangeAreaForImage(imageData: XImageShape, sheet: Excel.Worksheet, rangesMap: Map<string, RangeData>) {
  if (!imageData) return;
  const entireRange = sheet.getRange(imageData.from.address + ":" + imageData.to.address);
  const bottomRightRange = sheet.getRange(imageData.to.address);
  entireRange.load("top,left,height,width");
  bottomRightRange.load("top,left,height,width");
  rangesMap.set(imageData.name, { entireRange, bottomRightRange });
}

export const IMAGE_NAME_PREFIX = "Entrilia";
type RangeData = { entireRange: Excel.Range; bottomRightRange: Excel.Range };
