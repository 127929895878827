import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import { Grid, IconButton, Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { selectDeletingReport, selectProcessingReport } from "../../store/reportsSlice";
import { StepEnum, XEntriliaReportDescriptor } from "../../store/store.types";
import { selectClients, selectReportAccessState } from "../../store/userSlice";
import { EntriliaReport } from "../components/EntriliaReport";
import FiltersPanel from "./filtersPanel/FiltersPanel";
import WorkbookReportItemContent from "./WorkbookReportItemContent";
import WorkbookReportItemContentMoreOption from "./WorkbookReportItemContentMoreOption";
import WorkbookReportItemIcon from "./WorkbookReportItemIcon";

interface Props {
  report: XEntriliaReportDescriptor;
  activeWorksheet?: string;
  isReadonly: boolean;
  isDeleted: boolean;
}

export default function WorkbookReportItem({ report, activeWorksheet, isReadonly, isDeleted }: Props) {
  const clients = useSelector(selectClients);
  const processingReport = useSelector((s) => selectProcessingReport(s, report.id));
  const reportAccessState = useSelector((s) => selectReportAccessState(s, report.id));
  const deletingReport = useSelector((s) => selectDeletingReport(s, report.id));
  const [expanded, setExpanded] = React.useState(false);
  const entriliaReport = React.useMemo(() => new EntriliaReport(report), [report]);

  const reportCompanyLogo = clients.find((client) => client.clientCode === report.clientCode)?.branding.logoMarkUrl;

  const isReportLoading = !!processingReport && (processingReport.loading || processingReport.step === StepEnum.Done);

  return (
    <Grid
      container
      sx={(t) => ({
        border: "1px solid " + t.palette.divider,
        borderRadius: "4px",
        borderColor: activeWorksheet === report.worksheetId ? t.palette.primary.main : t.palette.divider,
        bgcolor: t.palette.background.paper,
      })}
    >
      <Grid
        container
        sx={(t) => ({
          alignItems: "center",
          borderRadius: "4px",
          p: 0.85,
          "&:hover": {
            cursor: "pointer",
            bgcolor: t.palette.action.hover,
          },
          gap: 0.5,
        })}
        onClick={() => entriliaReport.activateWorksheet()}
      >
        <IconButton
          size="small"
          sx={(theme) => ({ p: 0.4, color: theme.palette.secondary.light })}
          onClick={(evt) => {
            evt.stopPropagation();
            setExpanded((prev) => !prev);
          }}
          disabled={!!deletingReport}
        >
          {expanded ? <ArrowDropDownRoundedIcon /> : <ArrowRightRoundedIcon />}
        </IconButton>
        <Stack direction={"row"} flex={1} alignItems={"center"} spacing={1} minWidth={0}>
          <WorkbookReportItemIcon isDeleted={isDeleted} isReadonly={isReadonly} reportCompanyLogo={reportCompanyLogo} />
          <WorkbookReportItemContent
            report={report}
            isReadonly={isReadonly}
            isDeleted={isDeleted}
            precessingReport={processingReport}
            deletingReport={deletingReport}
            reportAccessState={reportAccessState}
          />
          {!isReportLoading && deletingReport === undefined && (
            <WorkbookReportItemContentMoreOption
              report={report}
              isDeleted={isDeleted}
              isReadonly={isReadonly}
              processingReport={processingReport}
            />
          )}
        </Stack>
      </Grid>
      <FiltersPanel
        expanded={expanded}
        report={report}
        filters={report.filters || []}
        isReadonly={isReadonly}
        isDeleted={isDeleted}
        reportAccessState={reportAccessState}
      />
    </Grid>
  );
}
