import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { XEntriliaReportDescriptor } from "../../../../store/store.types";
import { selectUserPermissions } from "../../../../store/userSlice";
import { hasPermissionsForAnyCompany } from "../../../helpers/accessUtils";
import RefreshReportMenu from "./RefreshReportMenu";

interface Props {
  activeReport: XEntriliaReportDescriptor | undefined;
}

export default function RefreshReportsButton({ activeReport }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const permissions = useSelector(selectUserPermissions);
  const isReadonlyMode = !hasPermissionsForAnyCompany(permissions);

  return (
    <>
      <Button
        variant="text"
        size="small"
        color="secondary"
        startIcon={<CachedRoundedIcon />}
        endIcon={<ArrowDropDownRoundedIcon />}
        disabled={isReadonlyMode}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        Refresh
      </Button>
      {anchorEl && (
        <RefreshReportMenu anchorEl={anchorEl} activeReport={activeReport} onClose={() => setAnchorEl(null)} />
      )}
    </>
  );
}
