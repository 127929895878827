import { XGroup } from "../hooks/useExcelDataApi.types";

export const loadGroupsAsync = async (context: Excel.RequestContext, sheet: Excel.Worksheet, groups: XGroup[]) => {
  const collapsedGroups = [];
  const expandedGroups = [];

  for (let index = 0; index < groups.length; index++) {
    const group = groups[index];
    if (group === undefined) continue;

    if (group.isCollapsed) {
      collapsedGroups.push(group);
    } else {
      expandedGroups.push(group);
    }
  }

  await loadAllGroupsAsync(context, sheet, collapsedGroups);
  iterateOverAllLevelGroups((gl) => sheet.showOutlineLevels(gl, gl));

  await loadAllGroupsAsync(context, sheet, expandedGroups);
};

const loadAllGroupsAsync = async (context: Excel.RequestContext, sheet: Excel.Worksheet, groups: XGroup[]) => {
  let hasSynced = true;

  for (let index = 0; index < groups.length; index++) {
    const group = groups[index];
    if (group === undefined) continue;
    hasSynced = false;
    const range = sheet.getRange(group.address);
    range.group(group.groupBy);

    if (index > 0 && index % 6000 === 0) {
      // eslint-disable-next-line office-addins/no-context-sync-in-loop
      await context.sync();
      hasSynced = true;
    }
  }

  if (!hasSynced) {
    await context.sync();
  }
};

export const iterateOverAllLevelGroups = (cb: (groupLevel: number) => void) => {
  const maxLvlGroup = 8;

  for (let groupLevel = maxLvlGroup; groupLevel > 0; groupLevel--) {
    cb(groupLevel);
  }
};
