import { ComponentType } from "react";
import { ExcelReportParameterDescription } from "../../../api/biApi.types";
import { SetParametersStepProps } from "../SetParametersStep";
import ValidateBiReportParameters from "../ValidateBiReportParameters";

interface Props extends SetParametersStepProps {
  parameters: ExcelReportParameterDescription[];
  reportId: string | undefined;
}

export default function withParametersValidation<T extends Props>(WrappedComponent: ComponentType<T>) {
  return function useParametersValidation(props: T) {
    return (
      <ValidateBiReportParameters
        reportId={props.reportId}
        parameters={props.parameters}
        clientCode={props.clientCode}
        reportSource={props.report.reportSource}
        reportName={props.report.name}
        reportCode={props.report.code}
      >
        <WrappedComponent {...props} />
      </ValidateBiReportParameters>
    );
  };
}
