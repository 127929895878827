import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { selectActiveReport } from "../../../store/reportsSlice";
import { selectUserPermissions } from "../../../store/userSlice";
import { hasPermissionsForAnyCompany } from "../../helpers/accessUtils";
import RefreshReportsButton from "./refresh-report/RefreshReportsButton";

interface Props {
  onAddReport: () => void;
}

export function ReportActions({ onAddReport }: Props) {
  const activeReport = useSelector(selectActiveReport);
  const permissions = useSelector(selectUserPermissions);
  const isReadonlyMode = !hasPermissionsForAnyCompany(permissions);

  return (
    <>
      <RefreshReportsButton activeReport={activeReport} />
      <Button
        variant="text"
        size="small"
        startIcon={<AddRoundedIcon />}
        disabled={isReadonlyMode || activeReport !== undefined}
        onClick={onAddReport}
      >
        Add Report
      </Button>
    </>
  );
}
