/* eslint-disable no-console */
import { ApplicationInsights, IConfig } from "@microsoft/applicationinsights-web";
import { getSettings } from "./variables";

let appInsights: ApplicationInsights | undefined = undefined;

export const initAppInsights = (config: IConfig): ApplicationInsights | undefined => {
  if (appInsights) {
    return appInsights;
  }
  const settings = getSettings();
  if (!settings.APP_INSIGHTS_CONN_STRING) {
    console.error("Missing app insights connection string");
    return undefined;
  }

  appInsights = new ApplicationInsights({
    config: {
      ...config,
      connectionString: settings.APP_INSIGHTS_CONN_STRING,
    },
  });

  appInsights.loadAppInsights();

  return appInsights;
};

const getErrorInstance = (error: unknown): Error => {
  if (error instanceof Error) {
    return error;
  }
  if (typeof error === "string") {
    return new Error(error);
  }
  return new Error(JSON.stringify(error));
};

export const logError = (error: unknown, context: string) => {
  console.error(context, error);

  if (appInsights) {
    appInsights.trackException(
      {
        exception: getErrorInstance(error),
      },
      { context }
    );
  }
};
