import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { LicenseInfo } from "@mui/x-license";
import { Provider } from "react-redux";
import { Outlet, Route, Routes } from "react-router-dom";
import { setUpAxios } from "./api/axiosHelper";
import { AppContainer } from "./auth/AppContainer";
import AuthenticationRequest from "./auth/AuthenticationRequest";
import { handleRedirectUrlState } from "./auth/handleRedirectUrlState";
import ProceedAuthentication from "./auth/ProceedAuthentication";
import { initAppInsights } from "./logging";
import AddReportPage from "./pages/addReport/AddReportPage";
import App from "./pages/App";
import EditReport from "./pages/editReport/EditReportPage";
import AccessForbiddenPage from "./pages/errors/AccessForbiddenPage";
import ErrorPage from "./pages/errors/ErrorPage";
import HomePage from "./pages/home/HomePage";
import LogoutPage from "./pages/logout/LogoutPage";
import ProcessSigninPage from "./pages/signin/ProcessSigninPage";
import SigninPage from "./pages/signin/SigninPage";
import { store } from "./store/store";
import { themeOptions } from "./theme";
import { PageNames } from "./types";

LicenseInfo.setLicenseKey(
  "75d46cffbb31c0218da13a5db9ae2dafTz03NzI4OCxFPTE3Mjk3NzA2OTgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

handleRedirectUrlState();
setUpAxios(initAppInsights({ appId: "ExcelAddin" }));

const theme = createTheme(themeOptions);
export default function Entry() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path={PageNames.RequestAuthentication} element={<AuthenticationRequest />} />
          <Route path={PageNames.ProceedAuthentication} element={<ProceedAuthentication />} />
          <Route path={PageNames.SignIn} element={<SigninPage />} />
          <Route path={PageNames.DoSignIn} element={<ProcessSigninPage />} />
          <Route path={PageNames.Logout} element={<LogoutPage />} />
          <Route path={PageNames.AccessForbidden} element={<AccessForbiddenPage />} />
          <Route path={PageNames.Error} element={<ErrorPage />} />
          <Route path="/" element={<AppContainer />}>
            <Route element={<App />}>
              <Route index element={<HomePage />} />
              <Route path={PageNames.Report} element={<Outlet />}>
                <Route index element={<AddReportPage />} />
                <Route path={":reportId"} element={<EditReport />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<div>404</div>}></Route>
        </Routes>
      </ThemeProvider>
    </Provider>
  );
}
