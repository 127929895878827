import { XEntriliaReportDeleting, XEntriliaReportPrecessing } from "../../store/store.types";
import WorkbookReportItemDeletingContent from "./WorkbookReportItemDeletingContent";
import WorkbookReportItemLoadingContent from "./WorkbookReportItemLoadingContent";

interface Props {
  processingReport: XEntriliaReportPrecessing | undefined;
  deletingReport: XEntriliaReportDeleting | undefined;
}

const WorkbookReportItemProcessing = ({ processingReport, deletingReport }: Props) => {
  if (processingReport?.loading) {
    return <WorkbookReportItemLoadingContent processingReport={processingReport} />;
  }
  if (deletingReport) {
    return <WorkbookReportItemDeletingContent />;
  }
  return null;
};

export default WorkbookReportItemProcessing;
