import TypographyMultilineEllipsis from "../components/common/TypographyMultilineEllipsis";

interface Props {
  text: string | undefined;
  color?: string;
}

const WorkbookReportItemContentCaption = ({ text, color }: Props) => {
  return (
    <TypographyMultilineEllipsis
      maxLines={2}
      typographyProps={{ variant: "subtitle2", lineHeight: "14.4px", fontSize: "12px", color }}
    >
      {text}
    </TypographyMultilineEllipsis>
  );
};

export default WorkbookReportItemContentCaption;
