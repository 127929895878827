import { Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { ReportAccessState } from "../../api/biApi.types";
import {
  StepEnum,
  XEntriliaReportDeleting,
  XEntriliaReportDescriptor,
  XEntriliaReportPrecessing,
} from "../../store/store.types";
import HorizontalFill from "../components/common/HorizontalFill";
import TypographyTooltipEllipsis from "../components/common/TypographyTooltipEllipsis";
import { getUpdatedAtText } from "./filtersPanel/conditionBlock.helper";
import WorkbookReportItemProcessing from "./WorkbookReportItemProcessing";

interface Props {
  report: XEntriliaReportDescriptor;
  isReadonly: boolean;
  processingReport: XEntriliaReportPrecessing | undefined;
  deletingReport: XEntriliaReportDeleting | undefined;
  reportAccessState: ReportAccessState | undefined;
}

const WorkbookReportItemContentDetails = ({
  report,
  isReadonly,
  deletingReport,
  processingReport,
  reportAccessState,
}: Props) => {
  const isReportLoading = !!processingReport && (processingReport.loading || processingReport.step === StepEnum.Done);
  const isDeletingReport = !!deletingReport;

  const isProcessingReport = isReportLoading || isDeletingReport;

  const updatedAtText = React.useMemo(() => {
    if (report.changedAt === undefined) return "Unknown";
    return new Date(report.changedAt).toLocaleString();
  }, [report.changedAt]);

  const error = processingReport?.error;

  if (error) {
    return (
      <Typography color="error" sx={{ fontSize: "10px" }}>
        {error}
      </Typography>
    );
  }

  if (!isProcessingReport && reportAccessState?.isConfigurationValid === false) {
    return (
      <TypographyTooltipEllipsis
        text={reportAccessState?.message ?? ""}
        typographyProps={{ sx: (t) => ({ fontSize: "10px", color: "warning.main" }) }}
      />
    );
  }

  if (isProcessingReport) {
    return <WorkbookReportItemProcessing processingReport={processingReport} deletingReport={deletingReport} />;
  }

  return (
    <Stack
      direction={"row"}
      flex={1}
      gap={1}
      flexWrap={"nowrap"}
      sx={(t) => ({ color: isReadonly ? t.palette.text.disabled : t.palette.text.secondary })}
    >
      <Tooltip
        title={getUpdatedAtText(report.changedAt, report.changedBy)}
        arrow
        sx={{ maxWidth: 175 }}
        disableInteractive
      >
        <Typography sx={{ fontSize: "10px", lineHeight: "12px" }} noWrap>
          {updatedAtText}
        </Typography>
      </Tooltip>
      <HorizontalFill />
      <Typography sx={{ fontSize: "10px", lineHeight: "12px" }} noWrap>
        {report.worksheetName}
      </Typography>
    </Stack>
  );
};

export default WorkbookReportItemContentDetails;
