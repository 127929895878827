import { Stack } from "@mui/material";
import { PropsWithChildren, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { BiApi } from "../../api/biApi";
import { GetReportsAccessStateRequest } from "../../api/biApi.types";
import useFetch from "../../hooks/useFetch";
import { selectReports } from "../../store/reportsSlice";
import { selectUserPermissions, UserInfoActions } from "../../store/userSlice";
import { PageNames } from "../../types";
import { distinctBy } from "../../utils/arrayHelper";
import { getCompanyWithViewReportsAccess } from "../helpers/accessUtils";
import InlineLoader from "./inlineLoader/InlineLoader";

const ReportsAccessibility = ({ children }: PropsWithChildren) => {
  const reportDescriptors = useSelector(selectReports);
  const permissions = useSelector(selectUserPermissions);
  const dispatch = useDispatch();

  const reportDescriptorsRef = useRef(reportDescriptors);
  reportDescriptorsRef.current = reportDescriptors;

  // We check reports accessibility only on the Addin launch and if permissions changed
  const fetchAccessibleReports = useCallback(async () => {
    const clientWithMinimumAccess = getCompanyWithViewReportsAccess(permissions);
    if (!clientWithMinimumAccess || reportDescriptorsRef.current.length === 0) {
      return { data: { data: [] }, success: true };
    }

    const request: GetReportsAccessStateRequest = {
      reportIds: distinctBy(
        reportDescriptorsRef.current.filter((r) => r.reportSource === "BI"),
        (r) => r.reportCode
      ).map((r) => r.reportCode),
      clientCode: clientWithMinimumAccess,
    };

    return await BiApi.getReportsAccessState(request);
  }, [permissions]);

  const [accessibleReports, accessibleReportsFetchError, { isFetching }] = useFetch(fetchAccessibleReports, (data) =>
    dispatch(UserInfoActions.setAccessibleReports({ access: data.data, storedReports: reportDescriptorsRef.current }))
  );

  if (accessibleReportsFetchError && !isFetching) {
    return <Navigate to={PageNames.Error} />;
  }

  if (isFetching && accessibleReports === undefined && !accessibleReportsFetchError) {
    return (
      <Stack position={"relative"} width={"100%"} height={"100%"}>
        <InlineLoader text="Loading reports information..." />
      </Stack>
    );
  }

  return <>{children}</>;
};

export default ReportsAccessibility;
