import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { selectReports } from "../../store/reportsSlice";
import { selectReportsAccessState, selectUserPermissions } from "../../store/userSlice";
import { selectActiveWorksheetId } from "../../store/workbookSlice";
import { isBiReportDeleted, isReadonlyReport } from "../helpers/accessUtils";
import WorkbookReportItem from "./WorkbookReportItem";

export default function WorkbookReports() {
  const reportDescriptors = useSelector(selectReports);
  const activateWorksheetId = useSelector(selectActiveWorksheetId);
  const reportsAccessState = useSelector(selectReportsAccessState);
  const permissions = useSelector(selectUserPermissions);

  return (
    <Grid container sx={{ flexDirection: "column", gap: 1 }}>
      {reportDescriptors.map((report) => (
        <WorkbookReportItem
          key={report.id}
          report={report}
          activeWorksheet={activateWorksheetId}
          isReadonly={isReadonlyReport(report, reportsAccessState, permissions)}
          isDeleted={isBiReportDeleted(report, reportsAccessState)}
        />
      ))}
    </Grid>
  );
}
