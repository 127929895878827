import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectReports, setActiveReport } from "../../../store/reportsSlice";
import { selectActiveWorksheetId, setActiveWorksheet } from "../../../store/workbookSlice";
import { excelLoadSingle } from "../utils/excelLoad";
import useExcelRunExecution from "./useExcelRunExecution";

export default function useActiveWorksheet() {
  const dispatch = useDispatch();
  const { excelRun } = useExcelRunExecution();

  const handleWorksheetActivation = React.useCallback(async () => {
    let eventHandle: OfficeExtension.EventHandlerResult<Excel.WorksheetActivatedEventArgs>;
    await excelRun(async (context) => {
      eventHandle = context.workbook.worksheets.onActivated.add(async (args) => {
        dispatch(setActiveWorksheet(args.worksheetId));
      });

      const worksheet = context.workbook.worksheets.getActiveWorksheet();
      const worksheetId = await excelLoadSingle(context, worksheet, "id");
      dispatch(setActiveWorksheet(worksheetId));
    });
    return () => eventHandle?.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    handleWorksheetActivation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useActiveReport() {
  const dispatch = useDispatch();
  const activeWorksheetId = useSelector(selectActiveWorksheetId);
  const reports = useSelector(selectReports);
  const activeReport = reports.find((r) => r.worksheetId === activeWorksheetId);

  React.useEffect(() => {
    dispatch(setActiveReport(activeReport));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeReport]);
}
