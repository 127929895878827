import { Alert } from "@mui/material";

const ReportIsDeletedAlert = () => {
  return (
    <Alert severity="warning" sx={{ mb: 2, width: "100%" }}>
      This report has been deleted from SmartView. Please contact your administrator for further assistance.
    </Alert>
  );
};

export default ReportIsDeletedAlert;
