import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useReportServiceContext } from "../../../../contexts/ReportServiceContext";
import RefreshAllReportsIcon from "../../../../icons/RefreshAllReportsIcon";
import { selectReports, selectReportsProcessing } from "../../../../store/reportsSlice";
import { XEntriliaReportDescriptor } from "../../../../store/store.types";
import { selectReportsAccessState, selectUserPermissions } from "../../../../store/userSlice";
import { PageNames } from "../../../../types";
import { isBiReportDeleted, isReadonlyReport } from "../../../helpers/accessUtils";

interface Props {
  anchorEl: HTMLElement | null;
  activeReport: XEntriliaReportDescriptor | undefined;
  onClose: () => void;
}

const RefreshReportMenu = ({ anchorEl, activeReport, onClose }: Props) => {
  const navigate = useNavigate();
  const storedReports = useSelector(selectReports);
  const permissions = useSelector(selectUserPermissions);
  const processingReports = useSelector(selectReportsProcessing);
  const reportsAccessState = useSelector(selectReportsAccessState);
  const { refreshReport, refreshAllReports } = useReportServiceContext();

  const reportIsProcessing = React.useMemo(
    () => processingReports.find((lr) => lr.id === activeReport?.id)?.loading,
    [activeReport, processingReports]
  );

  const notDeletedReportsWithAccess = useMemo(
    () =>
      storedReports.filter(
        (r) => !isReadonlyReport(r, reportsAccessState, permissions) && !isBiReportDeleted(r, reportsAccessState)
      ),
    [storedReports, permissions, reportsAccessState]
  );

  const handleRefreshCurrentReport = React.useCallback(() => {
    if (activeReport !== undefined) {
      refreshReport(activeReport.id);
    }
    onClose();
  }, [activeReport, onClose, refreshReport]);

  const handleRefreshAllReports = React.useCallback(() => {
    const notLoadingReports = notDeletedReportsWithAccess.filter(
      (r) => !processingReports.some((lr) => lr.id === r.id && lr.loading)
    );
    refreshAllReports(notLoadingReports.map((r) => r.id));
    onClose();
  }, [processingReports, onClose, notDeletedReportsWithAccess, refreshAllReports]);

  const navigateToEditReport = React.useCallback(
    () => navigate(`${PageNames.Report}/${activeReport?.id}`),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeReport?.id]
  );

  const canRunCurrentReport =
    activeReport &&
    !isReadonlyReport(activeReport, reportsAccessState, permissions) &&
    !isBiReportDeleted(activeReport, reportsAccessState);
  const refreshCurrentReportDisabled = reportIsProcessing || !canRunCurrentReport;
  const refreshWithNewFiltersDisabled = reportIsProcessing || !canRunCurrentReport;
  const refreshAllReportsDisabled = reportIsProcessing || notDeletedReportsWithAccess.length === 0;

  return (
    <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onClose}>
      <MenuItem disabled={refreshCurrentReportDisabled} onClick={handleRefreshCurrentReport}>
        <ListItemIcon>
          <CachedRoundedIcon fontSize="small" sx={(theme) => ({ color: theme.palette.secondary.light })} />
        </ListItemIcon>
        <Typography variant="body2" color={(theme) => theme.palette.text.primary}>
          Quick Refresh (Current Tab)
        </Typography>
      </MenuItem>
      <MenuItem disabled={refreshWithNewFiltersDisabled} onClick={navigateToEditReport}>
        <ListItemIcon>
          <TuneRoundedIcon fontSize="small" sx={(theme) => ({ color: theme.palette.secondary.light })} />
        </ListItemIcon>
        <Typography variant="body2" color={(theme) => theme.palette.text.primary}>
          Refresh with new Filters (Current Tab)
        </Typography>
      </MenuItem>
      <MenuItem disabled={refreshAllReportsDisabled} onClick={handleRefreshAllReports}>
        <ListItemIcon>
          <RefreshAllReportsIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="body2" color={(theme) => theme.palette.text.primary}>
          Refresh All
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default RefreshReportMenu;
