import { CanceledError } from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { concatMap, filter, map, Subject, tap } from "rxjs";
import { BiApi } from "../../../api/biApi";
import { ExcelReport, ReportSource, ValidateReportResponse } from "../../../api/biApi.types";
import {
  addReport,
  addRunningReportProcess,
  deleteReportById,
  removeReportProcessing,
  selectReports,
  selectReportsProcessing,
  updateAvailableCompaniesReportParameters,
  updateReport,
  updateReportProcessing,
} from "../../../store/reportsSlice";
import { StepEnum, XEntriliaReportDescriptor, XEntriliaReportParameter } from "../../../store/store.types";
import { selectUserInfo, UserInfoActions } from "../../../store/userSlice";
import {
  getParametersDescriptionForExistingReport,
  validateReportAsync,
} from "../../parameters/helpers/parametersHelper";
import { expandRangeForNewReportAndCleanArea, removeNamedRange } from "../DevTools";
import { ReportRequest } from "../types/reportService.types";
import populateWorksheet from "../utils/populateWorksheet";
import { checkReportStatus, createNewReport, requestReport } from "../utils/reportService.helper";
import { updateReportSetting } from "../utils/storedReportSettings";
import useCancellation from "./useCancellation";
import { XWorksheetData } from "./useExcelDataApi.types";
import useExcelRunExecution from "./useExcelRunExecution";

const canRunReport = (validationResult: ValidateReportResponse) => {
  return (
    validationResult.isConfigurationValid === true &&
    validationResult.hasAccessToReport === true &&
    validationResult.isReportDeleted !== true &&
    validationResult.error === undefined
  );
};

export default function useReportService() {
  const dispatch = useDispatch();
  const reports = useSelector(selectReports);
  const userInfo = useSelector(selectUserInfo);
  const processingReports = useSelector(selectReportsProcessing);
  const [requestReportSubject] = React.useState(() => new Subject<ReportRequest>());
  const { excelRun } = useExcelRunExecution();

  const reportsLoadingRef = React.useRef(processingReports);
  reportsLoadingRef.current = processingReports;

  const getGeneratedReport = React.useCallback(async (request: WaitingReportGeneratedResultData) => {
    if (request.requestCode === undefined) return { request, data: undefined };
    try {
      dispatch(
        updateReportProcessing({
          id: request.report.id,
          step: StepEnum.FetchGeneratedReport,
          stepDescription: "Fetching generated report",
        })
      );

      const response = await BiApi.getRequestedReport(
        request.report.reportSource ? request.report.reportSource : request.report.reportType,
        request.requestCode,
        request.report.clientCode,
        setCancellation(request.report.reportCode, request.report.worksheetId)?.abortController?.signal
      );

      if (response.success === true && response.data.data !== undefined) {
        return { request, data: response.data.data };
      }
      return { request };
    } catch (e) {
      return { request, cancelled: e instanceof CanceledError && e.name === "CanceledError" };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { cancelRequest, setCancellation, removeCancellation } = useCancellation();

  React.useEffect(() => {
    const subscription = requestReportSubject
      .pipe(
        concatMap(validateReport),
        tap(({ request, validationResult }) => {
          if (request.report.reportSource === ReportSource.BI && request.skipValidation !== true) {
            if (validationResult.error) {
              dispatch(
                updateReportProcessing({ id: request.report.id, error: validationResult.error, loading: false })
              );
              return;
            }

            // Update the report parameters in the available reports list. These parameters are user on SetParametersStep.
            dispatch(
              updateAvailableCompaniesReportParameters({
                companyCode: request.report.clientCode,
                reportCode: request.report.reportCode,
                parameters: validationResult.parameters.map((p) => p.parameter),
              })
            );

            dispatch(
              UserInfoActions.addOrUpdateAccessibleReport({
                access: {
                  reportCode: request.report.reportCode,
                  hasAccessToReport: validationResult.hasAccessToReport,
                  isReportDeleted: validationResult.isReportDeleted,
                  message: validationResult.validationErrorMessage,
                  isConfigurationValid: validationResult.isConfigurationValid,
                },
                storedReportId: request.report.id,
              })
            );
            if (!canRunReport(validationResult)) {
              dispatch(removeReportProcessing(request.report.id));
            }
          }
        }),
        filter((data): data is WaitingReportValidationResultData => canRunReport(data.validationResult)),
        map((data): ReportRequest => data.request),
        concatMap(waitReportGenerated),
        tap((result) => {
          if (result.cancelled) {
            if (result.isNewReport) {
              dispatch(deleteReportById(result.report.id));
            }
            dispatch(removeReportProcessing(result.report.id));
            removeCancellation(result.report.reportCode, result.report.worksheetId);
          } else if (result.generated === false) {
            dispatch(
              updateReportProcessing({
                id: result.report.id,
                loading: false,
                error: "Failed to generate report",
              })
            );
          }
        }),
        filter((result) => isReportInQueue(result.report) && result.generated),
        tap(({ report }) => {
          dispatch(
            updateReportProcessing({
              id: report.id,
              step: StepEnum.FetchGeneratedReport,
              stepDescription: "Pending to fetch generated report",
            })
          );
        }),
        concatMap(getGeneratedReport),
        filter((result) => isReportInQueue(result.request.report)),
        tap((result) => {
          dispatch(updateReportProcessing({ id: result.request.report.id, cancellable: false }));
          if (result.cancelled) {
            dispatch(removeReportProcessing(result.request.report.id));
            removeCancellation(result.request.report.reportCode, result.request.report.worksheetId);
          } else {
            if (result.data === undefined) {
              dispatch(
                updateReportProcessing({
                  id: result.request.report.id,
                  loading: false,
                  error: "Failed to fetch generated report",
                })
              );
            }
            dispatch(
              updateReportProcessing({
                id: result.request.report.id,
                step: StepEnum.PopulateReportToWorksheet,
                stepDescription: "Pending populating report to the worksheet",
              })
            );
          }
        }),
        filter(
          (result): result is { request: WaitingReportGeneratedResultData; data: XWorksheetData } =>
            result.data !== undefined
        ),
        concatMap((data) =>
          populateReportToWorksheet(
            data.request.report,
            data.data,
            data.request.filters,
            data.request.withParameters,
            data.request.selectedColumns
          )
        )
      )
      .pipe(
        map(
          (response) =>
            response as { success: boolean; report: XEntriliaReportDescriptor; filters: XEntriliaReportParameter[] }
        )
      )
      .subscribe((response) => {
        if (response.success) {
          // important for a new report to set the step to Done
          dispatch(
            updateReportProcessing({
              id: response.report.id,
              loading: false,
              step: StepEnum.Done,
              filters: response.filters,
            })
          );
          dispatch(
            UserInfoActions.addOrUpdateAccessibleReport({
              access: {
                reportCode: response.report.reportCode,
                hasAccessToReport: true,
                isReportDeleted: false,
                message: undefined,
                isConfigurationValid: true,
              },
              storedReportId: response.report.id,
            })
          );
          setTimeout(() => {
            dispatch(removeReportProcessing(response.report.id));
          }, 5000);
        } else {
          dispatch(
            updateReportProcessing({
              id: response.report.id,
              loading: false,
              error: "Failed to load report",
            })
          );
        }

        removeCancellation(response.report.reportCode, response.report.worksheetId);
      });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadReport = React.useCallback(
    async (
      report: XEntriliaReportDescriptor,
      filters: XEntriliaReportParameter[],
      withParameters: boolean,
      selectedColumns: number[] | undefined,
      isNewReport?: boolean,
      skipValidation?: boolean
    ) => {
      const reportInQueue = isReportInQueue(report);
      if (reportInQueue) return;
      if (report !== undefined && report.reportCode !== undefined) {
        dispatch(addRunningReportProcess({ reportId: report.id, caption: report.caption }));
        setTimeout(() => {
          requestReportSubject.next({ report, filters, withParameters, isNewReport, selectedColumns, skipValidation });
        }, 10);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const refreshReport = React.useCallback(
    async (reportId: string, skipValidation?: boolean) => {
      const report = reports.find((r) => r.id === reportId);
      if (report !== undefined) {
        reloadReport(report, report.filters, report.withParameters, report.selectedColumns, undefined, skipValidation);
      }
    },
    [reloadReport, reports]
  );

  const populateReportToWorksheet = React.useCallback(
    async (
      report: XEntriliaReportDescriptor,
      data: XWorksheetData,
      filters: XEntriliaReportParameter[],
      withParameters: boolean,
      selectedColumns: number[] | undefined
    ) => {
      dispatch(updateReportProcessing({ id: report.id, stepDescription: "Populating report to the worksheet" }));
      try {
        await excelRun(async (context) => {
          dispatch(updateReportProcessing({ id: report.id, stepDescription: "Checking reporting range" }));
          await expandRangeForNewReportAndCleanArea(context, report.worksheetId, data.dimension);

          dispatch(updateReportProcessing({ id: report.id, stepDescription: "Handle legacy report references" }));
          await removeNamedRange(context, report.worksheetId);

          dispatch(updateReportProcessing({ id: report.id, stepDescription: "Populate data to the worksheet" }));
          await populateWorksheet(context, data, report.worksheetId);

          dispatch(updateReportProcessing({ id: report.id, stepDescription: "Saving report" }));
          const updatedReport = await updateReportSetting(
            context,
            report,
            data.dimension.reference,
            filters,
            withParameters,
            selectedColumns,
            userInfo?.userName
          );
          if (updatedReport !== undefined) {
            dispatch(updateReport(updatedReport));
          }
        });
        return { success: true, report, filters: filters };
      } catch (error) {
        dispatch(
          updateReportProcessing({
            id: report.id,
            loading: false,
            error: "Failed to populate report to worksheet",
          })
        );
        return { success: false, report, filters: filters };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const addNewReport = React.useCallback(
    async (
      worksheetId: string,
      reportToGenerate: ExcelReport,
      filters: XEntriliaReportParameter[],
      clientCode: string,
      withParameters: boolean,
      selectedColumns: number[] | undefined,
      skipValidation?: boolean
    ) => {
      const newReport = createNewReport(
        worksheetId,
        reportToGenerate,
        filters,
        clientCode,
        withParameters,
        selectedColumns
      );
      dispatch(addReport(newReport));
      setTimeout(() => reloadReport(newReport, filters, withParameters, selectedColumns, true, skipValidation), 10);
      return newReport.id;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reloadReport]
  );

  const isReportInQueue = React.useCallback((report: XEntriliaReportDescriptor) => {
    return reportsLoadingRef.current.some((r) => r.id === report.id && r.loading);
  }, []);

  const waitReportGenerated = async (request: ReportRequest): Promise<WaitingReportGeneratedResultData> => {
    const performTask = async (resolve: (value: WaitingReportGeneratedResultData) => void) => {
      dispatch(updateReportProcessing({ id: request.report.id, stepDescription: "Pending" }));

      if (!isReportInQueue(request.report)) {
        resolve({
          report: request.report,
          generated: false,
          filters: request.filters,
          withParameters: request.withParameters,
          isNewReport: request.isNewReport,
          selectedColumns: request.selectedColumns,
        });
      } else {
        let reportExportCode;
        if (request.report.reportCode !== undefined) {
          dispatch(
            updateReportProcessing({
              id: request.report.id,
              step: StepEnum.RequestReportToGenerate,
              stepDescription: "Requesting report generation",
            })
          );
          const ct = setCancellation(request.report.reportCode, request.report.worksheetId);

          reportExportCode = await requestReport(request, ct?.abortController?.signal);
          if (ct?.isCancelled()) {
            resolve({
              report: request.report,
              generated: false,
              filters: request.filters,
              withParameters: request.withParameters,
              cancelled: true,
              isNewReport: request.isNewReport,
              selectedColumns: request.selectedColumns,
            });
            return;
          }
        }
        if (reportExportCode !== undefined) {
          dispatch(
            updateReportProcessing({
              id: request.report.id,
              step: StepEnum.WaitingReportToBeGenerated,
              stepDescription: "Waiting for the report to be generated",
              requestCode: reportExportCode,
            })
          );
          let attempt = 0;
          let isReportGenerated = false;
          const ct = setCancellation(request.report.reportCode, request.report.worksheetId);

          while (attempt < 200) {
            if (ct?.isCancelled()) {
              resolve({
                report: request.report,
                generated: false,
                filters: request.filters,
                withParameters: request.withParameters,
                cancelled: true,
                isNewReport: request.isNewReport,
                selectedColumns: request.selectedColumns,
              });
              return;
            }
            if (!isReportInQueue(request.report)) {
              break;
            }
            const result = await checkReportStatus(
              request.report.reportSource ? request.report.reportSource : request.report.reportType,
              reportExportCode,
              request.report.clientCode,
              ct?.abortController?.signal
            );
            if (result === undefined) {
              break;
            }
            if (result.success && result.data.status?.completed === true) {
              isReportGenerated = !result.data.status?.error;
              break;
            }

            attempt++;
            await timeout(1500);
          }

          resolve({
            report: request.report,
            generated: isReportGenerated,
            requestCode: reportExportCode,
            filters: request.filters,
            withParameters: request.withParameters,
            isNewReport: request.isNewReport,
            selectedColumns: request.selectedColumns,
            cancelled: ct?.isCancelled(),
          });
        } else {
          resolve({
            report: request.report,
            generated: false,
            filters: request.filters,
            withParameters: request.withParameters,
            isNewReport: request.isNewReport,
            selectedColumns: request.selectedColumns,
          });
        }
      }
    };
    return new Promise<WaitingReportGeneratedResultData>((resolve) => performTask(resolve));
  };

  const validateReport = React.useCallback(
    async (request: ReportRequest): Promise<WaitingReportValidationResultData> => {
      const performTask = async (resolve: (value: WaitingReportValidationResultData) => void) => {
        if (request.report.reportSource !== ReportSource.BI || request.skipValidation) {
          resolve({
            request,
            validationResult: {
              parameters: [],
              isReportDeleted: false,
              hasAccessToReport: true,
              isConfigurationValid: true,
            },
          });
          return;
        }
        const validationResult = await validateReportAsync(
          request.report.clientCode,
          request.report.reportCode,
          getParametersDescriptionForExistingReport(request.report.filters)
        );

        resolve({ request, validationResult });
      };

      return new Promise<WaitingReportValidationResultData>((resolve) => performTask(resolve));
    },
    []
  );

  return { addReport: addNewReport, refreshReport, reloadReport, cancelRequest };
}

export type ReportServiceReturnType = ReturnType<typeof useReportService>;

interface WaitingReportGeneratedResultData {
  report: XEntriliaReportDescriptor;
  generated: boolean;
  requestCode?: string;
  filters: XEntriliaReportParameter[];
  withParameters: boolean;
  cancelled?: boolean;
  isNewReport?: boolean;
  selectedColumns: number[] | undefined;
}

interface WaitingReportValidationResultData {
  request: ReportRequest;
  validationResult: ValidateReportResponse;
}

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
