import { ReportAccessState, ReportSource, UserPermissionSet } from "../../api/biApi.types";
import { XEntriliaReportDescriptor } from "../../store/store.types";

const hasAccessToReport = (report: XEntriliaReportDescriptor, userPermissions: UserPermissionSet[]) => {
  return userPermissions.some((p) => p.clientCode === report.clientCode && p.permissions.includes("ViewReports"));
};

export const hasPermissionsForAnyCompany = (userPermissions: UserPermissionSet[]) => {
  return userPermissions.some((p) => p.permissions.includes("ViewReports"));
};

export const hasPermissionsForCompany = (clientCode: string, userPermissions: UserPermissionSet[]) => {
  return userPermissions.some((p) => p.clientCode === clientCode && p.permissions.includes("ViewReports"));
};

export const getCompanyWithViewReportsAccess = (userPermissions: UserPermissionSet[]) => {
  return userPermissions.find((p) => p.permissions.includes("ViewReports"))?.clientCode;
};

export const isReadonlyReport = (
  report: XEntriliaReportDescriptor,
  reportsAccessState: ReportAccessState[],
  permissions: UserPermissionSet[]
) => {
  return !isReportAccessible(report, reportsAccessState) || !hasAccessToReport(report, permissions);
};

export const isReportAccessible = (report: XEntriliaReportDescriptor, reportsAccessState: ReportAccessState[]) => {
  if (report.reportSource !== ReportSource.BI) {
    return true;
  }
  const state = reportsAccessState.find((r) => r.storedReportId === report.id);
  if (!state) {
    return false;
  }

  if (state.isReportDeleted) {
    return true;
  }

  return state.hasAccessToReport === true;
};

export const isBiReportDeleted = (report: XEntriliaReportDescriptor, reportsAccessState: ReportAccessState[]) => {
  if (report.reportSource !== ReportSource.BI) {
    return false;
  }
  const state = reportsAccessState.find((r) => r.storedReportId === report.id);
  if (!state) {
    return false;
  }

  return state.isReportDeleted === true;
};
