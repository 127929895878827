import { Box, Typography } from "@mui/material";

interface Props {
  isReadonly: boolean;
  isDeleted: boolean;
}

const WorkbookReportItemContentWarningText = ({ isDeleted, isReadonly }: Props) => {
  if (isReadonly === false && isDeleted === false) {
    return null;
  }

  return (
    <Box>
      <Typography fontWeight={"400"} noWrap color={"text.secondary"}>
        {isReadonly ? "(No Access)" : "(Deleted)"}
      </Typography>
    </Box>
  );
};

export default WorkbookReportItemContentWarningText;
