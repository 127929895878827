import { createSlice } from "@reduxjs/toolkit";
import { updateReports } from "./reportsSlice";
import { RootState, WorkbookState } from "./store.types";

const initialState: WorkbookState = {
  initialLoadingCompleted: false,
  isInEditMode: false,
  isExecutingExcelRun: false,
};

const workbookStateSlice = createSlice({
  name: "workbookState",
  initialState,
  reducers: {
    update(state, action: { payload: Partial<WorkbookState> }) {
      Object.assign(state, action.payload);
    },
    setActiveWorksheet(state, action: { payload: string | undefined }) {
      state.activeWorksheetId = action.payload;
    },
    setEditMode(state, action: { payload: boolean }) {
      state.isInEditMode = action.payload;
    },
    setIsExecutingExcelRun(state, action: { payload: boolean }) {
      state.isExecutingExcelRun = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(updateReports, (state) => {
      state.initialLoadingCompleted = true;
    });
  },
});

export const { update, setActiveWorksheet, setEditMode, setIsExecutingExcelRun } = workbookStateSlice.actions;

export const selectInitialLoadingCompleted = (state: RootState) => state.workbook.initialLoadingCompleted;
export const selectActiveWorksheetId = (state: RootState) => state.workbook.activeWorksheetId;
export const selectIsInEditMode = (state: RootState) => state.workbook.isInEditMode;
export const selectIsExecutingExcelRun = (state: RootState) => state.workbook.isExecutingExcelRun;

export default workbookStateSlice.reducer;
