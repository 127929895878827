import { createSelector, createSlice } from "@reduxjs/toolkit";
import { ExcelReport, ExcelReportParameter } from "../api/biApi.types";
import { generateGuid } from "../utils/Utilities";
import {
  ReportsConfiguration,
  RootState,
  StepEnum,
  XEntriliaReportDescriptor,
  XEntriliaReportParameter,
  XEntriliaReportPrecessing,
} from "./store.types";

const initialState: ReportsConfiguration = {
  reports: [],
  availableCompanies: [],
  precessingReports: [],
  reportsDeleting: [],
};

const reportsSlice = createSlice({
  name: "reportsConfiguration",
  initialState,
  reducers: {
    updateReports(state, action: { payload: XEntriliaReportDescriptor[] | undefined }) {
      const ids = action.payload?.map((r) => r.id);
      state.reports = action.payload || [];
      state.reportsDeleting = state.reportsDeleting.filter((r) => ids?.includes(r.id));
    },
    updateReport(state, action: { payload: XEntriliaReportDescriptor }) {
      const reportIndex = state.reports.findIndex((r) => r.id === action.payload.id);
      if (reportIndex !== -1) {
        state.reports[reportIndex] = action.payload;
      }
    },
    updateReportParameters(
      state,
      action: { payload: { parameters: XEntriliaReportParameter[]; storedReportId: string } }
    ) {
      const { parameters, storedReportId } = action.payload;
      const reportIndex = state.reports.findIndex((r) => r.id === storedReportId);
      if (reportIndex !== -1) {
        const existingReport = state.reports[reportIndex];
        if (existingReport) {
          state.reports[reportIndex] = { ...existingReport, filters: parameters };
        }
      }
    },
    addReport(state, action: { payload: XEntriliaReportDescriptor }) {
      state.reports.push(action.payload);
    },
    deleteReportById(state, action: { payload: string }) {
      state.reports = state.reports.filter((r) => r.id !== action.payload);
    },
    setActiveReport(state, action: { payload: XEntriliaReportDescriptor | undefined }) {
      state.activeReport = action.payload;
    },
    updateTrigger(state) {
      state.trigger = generateGuid();
    },
    addRunningReportProcess(state, action: { payload: { reportId: string; caption: string } }) {
      const { reportId, caption } = action.payload;
      const report = state.precessingReports?.find((r) => r.id === reportId);
      const data: XEntriliaReportPrecessing = {
        id: reportId,
        loading: true,
        step: StepEnum.Pending,
        stepDescription: "Getting things ready",
        name: caption,
        cancellable: true,
        error: undefined,
      };
      if (report === undefined) {
        state.precessingReports.push(data);
      } else {
        Object.assign(report, data);
      }
    },
    updateReportProcessing(state, action: { payload: Partial<XEntriliaReportPrecessing> }) {
      const { payload } = action;
      const report = state.precessingReports?.find((r) => r.id === payload.id);
      if (report) {
        Object.assign(report, payload);
      }
    },
    removeReportProcessing(state, action: { payload: string }) {
      const { payload } = action;
      const reportIndex = state.precessingReports?.findIndex((r) => r.id === payload);
      if (reportIndex !== undefined && reportIndex > -1) {
        state.precessingReports?.splice(reportIndex, 1);
      }
    },
    addReportToDeleting(state, action: { payload: XEntriliaReportDescriptor }) {
      const { payload } = action;
      const report = state.reportsDeleting?.find((r) => r.id === payload.id);
      if (report === undefined) {
        state.reportsDeleting.push({ id: payload.id, caption: payload.caption });
      }
    },
    removeReportFromDeleting(state, action: { payload: string }) {
      const { payload } = action;
      const reportIndex = state.reportsDeleting?.findIndex((r) => r.id === payload);
      if (reportIndex !== undefined && reportIndex > -1) {
        state.reportsDeleting?.splice(reportIndex, 1);
      }
    },
    addAvailableCompanies(state, action: { payload: { companyCode: string; reports: ExcelReport[] } }) {
      const { companyCode, reports } = action.payload;
      const companyIndex = state.availableCompanies.findIndex((r) => r.companyCode === companyCode);

      if (companyIndex === -1) {
        state.availableCompanies.push({ companyCode, reports, areReportsLoaded: true });
        return;
      }
      Object.assign(state.availableCompanies[companyIndex] || { companyCode, reports }, {
        reports,
      });
    },
    updateAvailableCompaniesReportParameters(
      state,
      action: { payload: { companyCode: string; reportCode: string; parameters: ExcelReportParameter[] } }
    ) {
      const { companyCode, reportCode, parameters } = action.payload;
      const company = state.availableCompanies.find((r) => r.companyCode === companyCode);
      if (company) {
        const report = company.reports.find((r) => r.code === reportCode);
        if (report) {
          Object.assign(report, { parameters });
        }
      }
    },
  },
});

export const {
  updateReports,
  addReport,
  setActiveReport,
  updateTrigger,
  addRunningReportProcess,
  updateReportProcessing,
  removeReportProcessing,
  deleteReportById,
  addReportToDeleting,
  removeReportFromDeleting,
  addAvailableCompanies,
  updateReport,
  updateAvailableCompaniesReportParameters,
  updateReportParameters,
} = reportsSlice.actions;

export const selectReports = (state: RootState) => state.reports.reports;
export const selectActiveReport = (state: RootState) => state.reports.activeReport;
export const selectTrigger = (state: RootState) => state.reports.trigger;
export const selectReportsProcessing = (state: RootState) => state.reports.precessingReports;
export const selectProcessingReport = createSelector(
  [(state: RootState) => state.reports.precessingReports, (_, reportId: string | undefined) => reportId],
  (precessingReports, reportId) => precessingReports.find((r) => r.id === reportId)
);
export const selectReportsDeleting = (state: RootState) => state.reports.reportsDeleting;
export const selectDeletingReport = createSelector(
  [(state: RootState) => state.reports.reportsDeleting, (_, reportId: string | undefined) => reportId],
  (reportsDeleting, reportId) => reportsDeleting.find((r) => r.id === reportId)
);
export const selectAvailableCompanyReports = createSelector(
  [(state: RootState) => state.reports.availableCompanies, (_, companyCode: string | undefined) => companyCode],
  (availableCompanies, companyCode) => availableCompanies.find((r) => r.companyCode === companyCode)?.reports || []
);
export const selectAvailableCompaniesLoaded = createSelector(
  [(state: RootState) => state.reports.availableCompanies, (_, companyCode: string | undefined) => companyCode],
  (availableCompanies, companyCode) =>
    availableCompanies.find((r) => r.companyCode === companyCode)?.areReportsLoaded || false
);
export const selectAllAvailableCompanies = (state: RootState) => state.reports.availableCompanies;

export default reportsSlice.reducer;
