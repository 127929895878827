import axios from "axios";
import {
  isAuthTokenExist,
  isRefreshTokenExist,
  logout,
  redirectToAccessForbidden,
  redirectToRoot,
  redirectToSignIn,
} from "../auth/auth";
import { FuncArgs } from "../types";
import { InlineCodeExchangePath, InlineRefreshPath } from "./authApi";
import { ApiError, ApiResponse, ExtendedAxiosError } from "./axiosHelper.types";
import { authorizationHeader } from "./biApi";
import RefreshTokenService from "./RefreshTokenService";

export const setUpAxios = () => {
  axios.defaults.withCredentials = true;

  axios.interceptors.response.use(
    (res) => res,
    async (error) => {
      const axiosError = error as ExtendedAxiosError;
      if (!axiosError.isAxiosError) {
        return Promise.reject(error);
      }

      if (axiosError.response?.status === 403) {
        if (axiosError.config?.renewPermissionsOnAccessForbidden === true) {
          redirectToRoot();
        } else {
          redirectToAccessForbidden();
        }
        return Promise.resolve({ data: undefined });
      }

      if (
        axiosError.config.url?.includes(InlineRefreshPath) ||
        axiosError.config.url?.includes(InlineCodeExchangePath) ||
        !isRefreshTokenExist()
      ) {
        logout();
        redirectToSignIn();

        return Promise.resolve();
      }

      if (axiosError.response?.status !== 401 || axiosError.config?.skipAuthRefresh) {
        return Promise.reject(error);
      }

      if (isRefreshTokenExist() && (await RefreshTokenService.do())) {
        return axios.request({
          ...error.config,
          headers: { ...axiosError.config.headers, Authorization: authorizationHeader() },
          skipAuthRefresh: true,
        });
      }
      redirectToRoot();

      return Promise.resolve();
    }
  );

  axios.interceptors.request.use((request) => {
    if (!isAuthTokenExist() && isRefreshTokenExist() && !request.url?.includes(InlineRefreshPath)) {
      return RefreshTokenService.do().then(() => request);
    }

    return request;
  });
};

export const getErrorMessage = (error: unknown) => {
  if (!error) {
    return "";
  }

  if (typeof error === "string") {
    return error;
  }

  if (axios.isAxiosError(error) && error.message) {
    const responseData = error.response && (error.response.data as ApiResponse<null>);
    return responseData?.error?.message || error.message;
  }

  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error === "object" && "message" in error) {
    return error["message"] + "";
  }

  return error + "";
};

const getApiError = (error: unknown): ApiError => {
  if (!error) {
    return { message: "Unknown error occurred during API call" };
  }

  if (axios.isCancel(error)) {
    return { isCanceledRequest: true, message: error.message || "Request canceled" };
  }

  const errorMesage = getErrorMessage(error);
  if (!axios.isAxiosError(error)) {
    return { message: `Error occurred during API call: ${errorMesage}` };
  }

  const resp = error.response?.data;
  if (!resp) {
    return { message: `Request failed: ${errorMesage}` };
  }

  const apiResponse = resp as ApiResponse<undefined>;
  return apiResponse.error ?? { message: `Request failed: ${errorMesage}` };
};

export const getStatusCodeFromError = (error: unknown): number | undefined => {
  if (!error || !axios.isAxiosError(error)) {
    return undefined;
  }

  return error.response?.status;
};

export type HandledApiResponse<TData> = [TData, undefined] | [undefined, ApiError];

export const withErrorHandling =
  <TData, Args extends FuncArgs>(apiCall: (...args: Args) => Promise<ApiResponse<TData>>) =>
  async (...args: Args): Promise<HandledApiResponse<TData>> => {
    try {
      const resp = await apiCall(...args);
      if (resp.success) {
        return [resp.data, undefined];
      } else {
        const apiError = resp.error ?? { message: "Unknown API error" };
        return [undefined, apiError];
      }
    } catch (error) {
      const apiError = getApiError(error);
      return [undefined, apiError];
    }
  };
