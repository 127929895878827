import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateTrigger } from "../../store/reportsSlice";
import { StepEnum, XEntriliaReportPrecessing } from "../../store/store.types";
import LoadingReportsError from "../errors/LoadingReportsError";
import InlineLoaderSvg from "./inlineLoader/InlineLoaderSvg";

export interface Props {
  reportId: string | undefined;
  processingReport: XEntriliaReportPrecessing | undefined;
  handleReport: () => Promise<void>;
  refreshReport: (reportId: string) => Promise<void>;
  goHome: () => void;
  cancelRequest: () => void;
}

export default function ReportGeneration({
  reportId,
  processingReport,
  handleReport,
  refreshReport,
  goHome,
  cancelRequest,
}: Props) {
  const dispatch = useDispatch();
  const [error, setError] = React.useState<string>();
  const navigate = useNavigate();

  const tryAgain = React.useCallback(() => {
    setError(undefined);
    refreshReport(reportId || "");
  }, [refreshReport, reportId]);

  const hasRequested = React.useRef(false);

  React.useEffect(() => {
    if (hasRequested.current) {
      return;
    }
    hasRequested.current = true;
    setError(undefined);
    handleReport();
  }, [handleReport, setError]);

  React.useEffect(() => {
    if (processingReport?.error) {
      setError(processingReport?.error);
      return;
    }
    if (processingReport?.step === StepEnum.Done) {
      dispatch(updateTrigger());
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processingReport?.step, processingReport?.error]);

  const cancellable = processingReport?.cancellable === true;

  return (
    <>
      {!error && (
        <Stack spacing={2.5} flex={1} alignItems={"center"} justifyContent={"center"}>
          <Box sx={{ width: "5rem", mx: "auto" }}>
            <InlineLoaderSvg />
          </Box>
          <Stack spacing={1.25} alignItems={"center"}>
            <Typography variant="subtitle1">{processingReport?.name}</Typography>
            <Typography>{processingReport?.stepDescription}</Typography>
          </Stack>
          <Button
            variant="outlined"
            color="secondary"
            size="medium"
            sx={{ width: "147px" }}
            disabled={!cancellable}
            onClick={cancelRequest}
          >
            Cancel
          </Button>
        </Stack>
      )}
      {error && <LoadingReportsError goHome={goHome} retryToLoadReports={tryAgain} errorMessage={error} />}
    </>
  );
}
