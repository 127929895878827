import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { withErrorHandling } from "../api/axiosHelper";
import { ApiError, ApiResponse } from "../api/axiosHelper.types";
import { AsyncFunc } from "../types";

type Result<TData> = [
  TData | undefined,
  ApiError | undefined,
  {
    setData: Dispatch<SetStateAction<TData | undefined>>;
    isFetching: boolean;
    fetch: (cancellationToken?: CancellationToken) => Promise<void>;
  },
];

interface CancellationToken {
  cancel: boolean;
}

const useFetch = <TData>(apiCall: AsyncFunc<ApiResponse<TData>>, onSetData?: (data: TData) => void): Result<TData> => {
  const [data, setData] = useState<TData>();
  const [error, setError] = useState<ApiError>();
  const [isFetching, setIsFetching] = useState(false);

  const handledApiCall = useMemo(() => withErrorHandling(apiCall), [apiCall]);

  const fetch = useCallback(
    async (cancellationToken?: CancellationToken) => {
      setIsFetching(true);
      setError(undefined);

      const [responseData, responseError] = await handledApiCall();

      if (cancellationToken?.cancel) {
        return;
      }

      if (responseError) {
        setError(responseError.isCanceledRequest ? undefined : responseError);
      } else {
        setData(responseData);
        onSetData?.(responseData);
      }

      setIsFetching(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handledApiCall]
  );

  useEffect(() => {
    const cancellationToken: CancellationToken = { cancel: false };
    fetch(cancellationToken);
    return () => {
      cancellationToken.cancel = true;
    };
  }, [fetch]);

  return [data, error, { setData, isFetching, fetch }];
};

export default useFetch;
