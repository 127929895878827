/* eslint-disable @typescript-eslint/no-explicit-any */
export type EmptyObject = Record<string, never>;

export type AnyObject = Record<string, unknown>;

export type AsyncFunc<TResult> = (...args: any[]) => Promise<TResult>;

export type Action = (...args: any[]) => void;

export type FuncArgs = any[];

export enum PageNames {
  RequestAuthentication = "auth",
  ProceedAuthentication = "oauth_signin",
  SignIn = "signin",
  DoSignIn = "do_signin",
  Logout = "logout",
  AccessForbidden = "access_forbidden",
  AccessNotPermitted = "access_not_permitted",
  Error = "error",
  Report = "report",
}
