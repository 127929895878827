import { Alert } from "@mui/material";

const ReportConfigurationIsOutdatedAlert = () => {
  return (
    <Alert severity="warning">
      Local report configuration was outdated and has been successfully updated to the latest version.
    </Alert>
  );
};

export default ReportConfigurationIsOutdatedAlert;
