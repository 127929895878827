import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PageNames } from "../../../types";
import HorizontalFill from "../../components/common/HorizontalFill";
import ScrollableFlexContainer from "../../components/common/ScrollableFlexContainer";
import WorkbookReports from "../WorkbookReports";
import { ReportActions } from "./ReportActions";

const Reports = () => {
  const navigate = useNavigate();
  const navigateToAddReport = () => navigate(PageNames.Report);

  return (
    <>
      <Grid container sx={{ flexDirection: "row", alignItems: "center", gap: 1 }}>
        <Typography variant="subtitle2">Reports</Typography>
        <HorizontalFill />
        <ReportActions onAddReport={navigateToAddReport} />
      </Grid>
      <ScrollableFlexContainer>
        <WorkbookReports />
      </ScrollableFlexContainer>
    </>
  );
};

export default Reports;
