import axios, { AxiosRequestConfig } from "axios";
import { getAuthToken } from "../auth/auth";
import { XEntriliaReportParameter } from "../store/store.types";
import { getBackendBaseUrl } from "../variables";
import { ApiResponse } from "./axiosHelper.types";
import {
  ClientsResponse,
  ExcelReport,
  ExcelReportParameterDescription,
  ExportedReportDataResponse,
  ExportedReportStatusResponse,
  GetReportsAccessStateRequest,
  ParameterDictionaryResponse,
  ReportAccessStateResponse,
  ReportKind,
  ReportSource,
  RequestReportResponse,
  UserData,
  UserPermissionsInfo,
  ValidateReportResponse,
} from "./biApi.types";

const baseUri = getBackendBaseUrl("reporting");

export const authorizationHeader = () => {
  const token = getAuthToken();
  return token ? `Bearer ${getAuthToken()}` : "";
};
const getClientCodeHeader = (clientCode: string) => ({ SelectedClientCode: clientCode });

export const BiApi = {
  getAvailableReports: async (clientCode: string, signal?: AbortSignal) => {
    const { data } = await axios.get<ApiResponse<ExcelReport[]>>(`${baseUri}/ExcelData/reports`, {
      headers: { ...getClientCodeHeader(clientCode), Authorization: authorizationHeader() },
      signal,
      renewPermissionsOnAccessForbidden: true,
    } as AxiosRequestConfig);
    return data;
  },

  requestReport: async (
    reportCode: string,
    reportSource: ReportSource | undefined,
    reportKind: ReportKind | undefined,
    parameters: XEntriliaReportParameter[],
    withParameters: boolean,
    selectedColumns: number[] | undefined,
    clientCode: string,
    signal?: AbortSignal
  ) => {
    const { data } = await axios.post<ApiResponse<RequestReportResponse>>(
      `${baseUri}/ExcelData/requestReport`,
      {
        reportCode,
        reportSource,
        reportKind,
        parameters,
        withParameters,
        columns: selectedColumns,
      },
      {
        headers: { ...getClientCodeHeader(clientCode), Authorization: authorizationHeader() },
        signal,
        renewPermissionsOnAccessForbidden: true,
      } as AxiosRequestConfig
    );
    return data;
  },
  getRequestedReportStatus: async (
    reportSource: ReportSource | string | undefined,
    reportExportCode: string,
    clientCode: string,
    signal?: AbortSignal
  ) => {
    const { data } = await axios.get<ApiResponse<ExportedReportStatusResponse>>(
      `${baseUri}/ExcelData/report/${reportSource}/${reportExportCode}/status`,
      {
        headers: { ...getClientCodeHeader(clientCode), Authorization: authorizationHeader() },
        signal,
        renewPermissionsOnAccessForbidden: true,
      } as AxiosRequestConfig
    );
    return data;
  },
  getRequestedReport: async (
    reportType: ReportSource | string | undefined,
    reportExportCode: string,
    clientCode: string,
    signal?: AbortSignal
  ) => {
    const { data } = await axios.get<ApiResponse<ExportedReportDataResponse>>(
      `${baseUri}/ExcelData/report/${reportType}/${reportExportCode}`,
      {
        headers: { ...getClientCodeHeader(clientCode), Authorization: authorizationHeader() },
        signal,
        renewPermissionsOnAccessForbidden: true,
      } as AxiosRequestConfig
    );
    return data;
  },
  getParameterDictionary: async (reportType: ReportSource, parameterName: string, clientCode: string) => {
    const { data } = await axios.get<ApiResponse<ParameterDictionaryResponse>>(
      `${baseUri}/ExcelData/dictionary/${reportType}/${parameterName}`,
      {
        headers: { ...getClientCodeHeader(clientCode), Authorization: authorizationHeader() },
        renewPermissionsOnAccessForbidden: true,
      } as AxiosRequestConfig
    );
    return data;
  },
  getUserPermissions: async () => {
    const { data } = await axios.get<ApiResponse<UserPermissionsInfo>>(`${baseUri}/Users/current/permissions`, {
      headers: { Authorization: authorizationHeader() },
    });
    return data;
  },

  getClients: async () => {
    const { data } = await axios.get<ApiResponse<ClientsResponse>>(`${baseUri}/Clients`, {
      headers: { Authorization: authorizationHeader() },
    });
    return data;
  },

  getUserData: async (): Promise<ApiResponse<UserData> | undefined> => {
    const [userInfoResp, clientsResp] = await Promise.all([BiApi.getUserPermissions(), BiApi.getClients()]);

    if (userInfoResp === undefined || clientsResp === undefined) return undefined;
    if (!userInfoResp.success) {
      return { success: false, error: userInfoResp.error };
    }
    if (!clientsResp.success) {
      return { success: false, error: clientsResp.error };
    }

    return {
      success: true,
      data: { ...filterOnlyAllowedCompanies(userInfoResp.data), clients: clientsResp.data.clients },
    };
  },

  getReportsAccessState: async (request: GetReportsAccessStateRequest) => {
    const queryParams = new URLSearchParams();
    request.reportIds.map((cc) => queryParams.append("reportIds", cc));

    const { data } = await axios.get<ApiResponse<ReportAccessStateResponse>>(
      `${baseUri}/ExcelData/reports/access-state?${queryParams.toString()}`,
      {
        headers: { ...getClientCodeHeader(request.clientCode), Authorization: authorizationHeader() },
        renewPermissionsOnAccessForbidden: true,
      } as AxiosRequestConfig
    );
    return data;
  },

  validateReportConfiguration: async (
    reportCode: string,
    clientCode: string,
    currentParameters: ExcelReportParameterDescription[],
    signal?: AbortSignal
  ) => {
    const { data } = await axios.post<ApiResponse<ValidateReportResponse>>(
      `${baseUri}/ExcelData/validate/${reportCode}`,
      {
        parameters: currentParameters,
      },
      {
        headers: { ...getClientCodeHeader(clientCode), Authorization: authorizationHeader() },
        signal,
        renewPermissionsOnAccessForbidden: true,
      } as AxiosRequestConfig
    );
    return data;
  },
};

function filterOnlyAllowedCompanies(data: UserPermissionsInfo): UserPermissionsInfo {
  return {
    ...data,
    permissions: data.permissions.filter(
      (p) => p.permissions.includes("ViewReports") || p.permissions.includes("ManageReports")
    ),
  };
}

export type CancellationToken = { cancel: () => void };
