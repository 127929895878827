import { withErrorHandling } from "../../../api/axiosHelper";
import { BiApi } from "../../../api/biApi";
import {
  ExcelReportParameter,
  ExcelReportParameterDescription,
  ValidateReportResponse,
} from "../../../api/biApi.types";
import { logError } from "../../../logging";
import { XEntriliaReportParameter } from "../../../store/store.types";

export const getParameterValue = (obj: unknown) => {
  return getValueByKey(obj, 0);
};

export const getParameterText = (obj: unknown) => {
  return getValueByKey(obj, 1);
};

function getValueByKey(obj: unknown, index: number) {
  if (typeof obj !== "object" || obj === null) {
    return "";
  }

  const keys = Object.keys(obj);
  if (keys.length === 0) {
    return "";
  }

  const firstKey = keys[index];
  if (firstKey === undefined) {
    return "";
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (obj as { [key: string]: any })[firstKey];
}

export const getParametersDescriptionForExistingReport = (
  parameters: XEntriliaReportParameter[] | undefined
): ExcelReportParameterDescription[] => {
  return (parameters || []).map((f) => ({
    id: f.id,
    selectedOptions: f.values.map((v) => v ?? ""),
    equalityType: f.equalityType,
    type: f.type,
  }));
};

export const getParametersDescriptionForNewReport = (
  parameters: ExcelReportParameter[] | undefined
): ExcelReportParameterDescription[] => {
  return (parameters || []).map((f) => ({
    id: f.id,
    selectedOptions: [],
    equalityType: f.equalityType,
    type: f.type,
  }));
};
const validateReportConfiguration = withErrorHandling(BiApi.validateReportConfiguration);

export const validateReportAsync = async (
  clientCode: string,
  reportCode: string,
  parameters: ExcelReportParameterDescription[]
): Promise<ValidateReportResponse> => {
  const [refinedParametersResponse, error] = await validateReportConfiguration(reportCode, clientCode, parameters);

  if (error) {
    logError(error, "validateReportAsync");
    return { parameters: [], error: "Error while validating report parameters" };
  }

  return {
    parameters: refinedParametersResponse.parameters,
    validationErrorMessage: refinedParametersResponse.validationErrorMessage,
    hasAccessToReport: refinedParametersResponse.hasAccessToReport,
    isReportDeleted: refinedParametersResponse.isReportDeleted,
    isConfigurationValid: refinedParametersResponse.isConfigurationValid,
  };
};
