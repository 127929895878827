import { useCallback, useEffect, useState } from "react";
import { selectReportsProcessing } from "../../../store/reportsSlice";
import { useSelector } from "react-redux";
import useExcelRunExecution from "./useExcelRunExecution";
import { logError } from "../../../logging";

type CalculationMode = "Automatic" | "AutomaticExceptTables" | "Manual";

const useRefreshAllReports = (refreshReports: (ids: string[]) => Promise<string[]>) => {
  const processingReports = useSelector(selectReportsProcessing);
  const [userSelectedCalculationMode, setUserSelectedCalculationMode] = useState<CalculationMode | undefined>();
  const [reportIds, setReportIds] = useState<string[]>([]);

  const { excelRun } = useExcelRunExecution();

  const manageCalculationMode = useCallback(async () => {
    await excelRun(async (context) => {
      context.application.load("calculationMode");
      await context.sync();

      setUserSelectedCalculationMode(context.application.calculationMode);
      context.application.calculationMode = "Manual";
      await context.sync();
    });
  }, [excelRun]);

  const refreshAllReports = useCallback(
    async (reportIds: string[]) => {
      try {
        await manageCalculationMode();
        setReportIds(await refreshReports(reportIds));
      } catch (e) {
        logError(e, "refreshAllReports");
      }
    },
    [refreshReports, manageCalculationMode]
  );

  const revert = useCallback(async () => {
    await excelRun(async (context) => {
      if (userSelectedCalculationMode) {
        context.application.calculationMode = userSelectedCalculationMode;
        await context.sync();
      }
      setUserSelectedCalculationMode(undefined);
      setReportIds([]);
    });
  }, [excelRun, userSelectedCalculationMode]);

  useEffect(() => {
    if (reportIds.length === 0) {
      return;
    }

    const allReportsAreDone = reportIds.every((id) => !processingReports.some((r) => r.id === id));
    if (allReportsAreDone) {
      revert();
    }
  }, [processingReports, reportIds, revert]);

  return { refreshAllReports };
};

export default useRefreshAllReports;
