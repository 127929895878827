import { ReportKind, ReportSource } from "../../../api/biApi.types";
import { logError } from "../../../logging";
import { XEntriliaReportDescriptor, XEntriliaReportParameter } from "../../../store/store.types";
import { XEntriliaReportPrefix, XEntriliaReportSettingsKey } from "../hooks/useEntriliaReportsMonitoring.types";
import getShortUniqueId from "./getShortUniqueId";

/** Loads the Entrilia Reports Settings from the Workbook */
export async function getStoredReportSettings(context: Excel.RequestContext): Promise<XEntriliaReportDescriptor[]> {
  try {
    context.workbook.settings.load("items/key,items/value");
    await context.sync();
    const settingsExist = context.workbook.settings.items.find((s) => s.key === XEntriliaReportSettingsKey);
    if (!settingsExist) return [];
    return JSON.parse(settingsExist.value) as XEntriliaReportDescriptor[];
  } catch (error) {
    logError(error, "getStoredReportSettings");
    return [];
  }
}

export async function addReportSetting(
  reportId: string,
  context: Excel.RequestContext,
  worksheetId: string,
  address: string,
  caption: string,
  code: string,
  filters: XEntriliaReportParameter[],
  clientCode: string,
  reportType: string,
  withParameters: boolean,
  reportSource: ReportSource,
  reportKind: ReportKind,
  selectedColumns: number[] | undefined,
  changedBy?: string
) {
  const storedReportSettings = await getStoredReportSettings(context);
  const worksheet = context.workbook.worksheets.getItem(worksheetId);
  context.load(worksheet, ["name", "id"]);
  await context.sync();

  const newSettings: XEntriliaReportDescriptor = {
    id: reportId,
    address,
    originalAddress: address,
    worksheetName: worksheet.name,
    worksheetId: worksheet.id,
    caption,
    reportCode: code,
    filters,
    changedAt: new Date().getTime(),
    clientCode,
    reportSource,
    reportKind,
    reportType,
    changedBy,
    withParameters,
    selectedColumns,
  };

  await storeReportSettings(context, [...storedReportSettings, newSettings]);

  const namedRange = worksheet.getRange(address);
  worksheet.names.add(reportId, namedRange);
  await context.sync();

  return newSettings;
}

export async function updateReportSetting(
  context: Excel.RequestContext,
  report: XEntriliaReportDescriptor,
  address: string,
  filters: XEntriliaReportParameter[],
  withParameters: boolean,
  selectedColumns: number[] | undefined,
  changedBy?: string
) {
  if (report.reportCode === undefined) return;
  const storedReportSettings = await getStoredReportSettings(context);
  const worksheet = context.workbook.worksheets.getItem(report.worksheetId);
  context.load(worksheet, ["name", "id"]);
  await context.sync();

  const storedReport = storedReportSettings.find((s) => s.id === report.id);
  if (storedReport === undefined) {
    return await addReportSetting(
      report.id,
      context,
      report.worksheetId,
      address,
      report.caption,
      report.reportCode,
      filters,
      report.clientCode,
      report.reportType,
      withParameters,
      report.reportSource,
      report.reportKind,
      selectedColumns,
      changedBy
    );
  }

  storedReport.address = address;
  storedReport.originalAddress = address;
  storedReport.changedAt = new Date().getTime();
  storedReport.filters = filters;
  storedReport.changedBy = changedBy;
  storedReport.withParameters = withParameters;
  storedReport.selectedColumns = selectedColumns;

  await storeReportSettings(context, storedReportSettings);

  context.load(worksheet, "names/items/name");
  await context.sync();

  const namedItem = worksheet.names.items.find((i) => i.name === report.id);
  if (namedItem !== undefined) {
    namedItem.delete();
    await context.sync();
  }

  const namedRange = worksheet.getRange(address);
  worksheet.names.add(report.id, namedRange);
  await context.sync();

  return storedReport;
}

export async function storeReportSettings(context: Excel.RequestContext, reportSettings: XEntriliaReportDescriptor[]) {
  const settingsExist = context.workbook.settings.items.some((s) => s.key === XEntriliaReportSettingsKey);
  if (!settingsExist) {
    context.workbook.settings.add(XEntriliaReportSettingsKey, JSON.stringify(reportSettings));
  } else {
    const settings = context.workbook.settings.getItem(XEntriliaReportSettingsKey);
    settings.value = JSON.stringify(reportSettings);
  }

  await context.sync();
}

export function generateReportId() {
  return `${XEntriliaReportPrefix}_${getShortUniqueId()}`;
}
