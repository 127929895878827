import { XEntriliaReportDescriptor } from "../../store/store.types";

export class EntriliaReport {
  constructor(public descriptor: XEntriliaReportDescriptor) {}

  public async activateWorksheet(): Promise<void> {
    await Excel.run({ delayForCellEdit: true }, async (context) => {
      const worksheet = context.workbook.worksheets.getItem(this.descriptor.worksheetId);
      worksheet.activate();
    });
  }
}
