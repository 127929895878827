import { Alert } from "@mui/material";

const ReportAccessRestrictedAlert = () => {
  return (
    <Alert severity="warning" sx={{ mb: 2, width: "100%" }}>
      You do not have the required permission to access this report. Please contact your administrator for further
      assistance.
    </Alert>
  );
};

export default ReportAccessRestrictedAlert;
