import React from "react";
import { defined } from "../utils/typeHelper";
import { ReportServiceReturnType } from "../pages/components/hooks/useReportService";

interface ContextType extends ReportServiceReturnType {
  refreshAllReports: (ids: string[]) => Promise<void>;
}

const ReportServiceContext = React.createContext<ContextType | undefined>(undefined);

export const ReportServiceContextProvider = (props: React.PropsWithChildren<ContextType>) => (
  <ReportServiceContext.Provider value={{ ...props }}>{props.children}</ReportServiceContext.Provider>
);

export const useReportServiceContext = () => {
  const context = React.useContext(ReportServiceContext);
  return defined(context);
};
