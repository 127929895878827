import { Box, Stack } from "@mui/material";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ExcelReportParameterDescription, ReportSource } from "../../api/biApi.types";
import {
  addRunningReportProcess,
  removeReportProcessing,
  updateAvailableCompaniesReportParameters,
  updateReportParameters,
  updateTrigger,
} from "../../store/reportsSlice";
import { XEntriliaReportParameter } from "../../store/store.types";
import { UserInfoActions } from "../../store/userSlice";
import ReportConfigurationIsOutdatedAlert from "../components/common/alerts/ReportConfigurationIsOutdatedAlert";
import InlineLoader from "../components/inlineLoader/InlineLoader";
import LoadingReportsError from "../errors/LoadingReportsError";
import { validateReportAsync } from "./helpers/parametersHelper";

interface Props {
  clientCode: string;
  reportCode: string;
  parameters: ExcelReportParameterDescription[];
  reportSource: ReportSource;
  reportName: string;
  reportId: string | undefined;
}

const NEW_REPORT_ID = "New Report";

const ValidateBiReportParameters = ({
  children,
  parameters,
  reportId,
  clientCode,
  reportSource,
  reportName,
  reportCode,
}: PropsWithChildren<Props>) => {
  const [configurationUpdatedMessage, setConfigurationUpdatedMessage] = useState<string>();
  const [isValidatingParameters, setIsValidatingParameters] = useState(false);
  const [error, setError] = useState<string>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goHome = () => {
    if (reportId !== undefined) {
      dispatch(removeReportProcessing(reportId));
    }
    dispatch(updateTrigger());
    navigate("/");
  };

  const validateReportParameters = useCallback(() => {
    if (reportSource !== ReportSource.BI) {
      return;
    }
    const storedReportId = reportId ?? NEW_REPORT_ID; // For a new report, reportId is not generated

    setIsValidatingParameters(true);
    dispatch(removeReportProcessing(storedReportId));
    dispatch(addRunningReportProcess({ reportId: storedReportId, caption: reportName }));

    validateReportAsync(clientCode, reportCode, parameters)
      .then((validationResult) => {
        if (validationResult.error) {
          setError("Failed to validate report configuration");
          return;
        }

        // Ignore for a new report
        if (storedReportId !== NEW_REPORT_ID) {
          dispatch(
            UserInfoActions.addOrUpdateAccessibleReport({
              access: {
                reportCode: reportCode,
                hasAccessToReport: validationResult.hasAccessToReport,
                isReportDeleted: validationResult.isReportDeleted,
                message: validationResult.validationErrorMessage,
                isConfigurationValid: validationResult.isConfigurationValid,
              },
              storedReportId: storedReportId,
            })
          );
          if (validationResult.validationErrorMessage) {
            setConfigurationUpdatedMessage(validationResult.validationErrorMessage);
          }
        }

        dispatch(
          updateAvailableCompaniesReportParameters({
            companyCode: clientCode,
            reportCode: reportCode,
            parameters: validationResult.parameters.map((p) => p.parameter),
          })
        );

        const selectedFilter: XEntriliaReportParameter[] = validationResult.parameters.map((p) => ({
          id: p.parameter.id,
          name: p.parameter.name,
          description: p.parameter.description,
          reportSource: p.parameter.reportSource,
          values: [...p.userSelectedValues],
          equalityType: p.parameter.equalityType,
          type: p.parameter.type,
        }));
        dispatch(updateReportParameters({ storedReportId: storedReportId, parameters: selectedFilter }));
      })
      .finally(() => {
        dispatch(removeReportProcessing(storedReportId));
        setIsValidatingParameters(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId, reportSource, clientCode, parameters, reportCode, reportName]);

  const tryAgain = () => {
    setError(undefined);
    validateReportParameters();
  };

  useEffect(() => {
    validateReportParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return <LoadingReportsError goHome={goHome} retryToLoadReports={tryAgain} errorMessage={error} />;
  }

  return (
    <Stack height={"100%"} position={"relative"}>
      {isValidatingParameters && <InlineLoader text="Getting things ready..." />}
      {!isValidatingParameters && (
        <Stack height={"inherit"}>
          {configurationUpdatedMessage && (
            <Box pt={2} px={2.5}>
              <ReportConfigurationIsOutdatedAlert />
            </Box>
          )}
          {children}
        </Stack>
      )}
    </Stack>
  );
};

export default ValidateBiReportParameters;
