import { Typography } from "@mui/material";
import { useMemo } from "react";
import { ReportSource } from "../../../../api/biApi.types";
import { XEntriliaReportDescriptor } from "../../../../store/store.types";
import InfoBlockItem from "./InfoBlockItem";

interface Props {
  report: XEntriliaReportDescriptor;
  companyTitle: string;
  isReadonly: boolean;
}

const InfoBlock = ({ report, companyTitle, isReadonly }: Props) => {
  const source = useMemo(() => {
    let s = report.reportSource === ReportSource.BC ? "Fund Operations" : "Reporting";
    if (report.reportKind === "MasterDataReport") {
      s += ", Master Data";
    }
    return s;
  }, [report.reportKind, report.reportSource]);
  return (
    <>
      <Typography variant="subtitle2" sx={{ mb: 0.1 }}>
        Info
      </Typography>
      {isReadonly ? (
        <InfoBlockItem name="Company Code" title={report.clientCode} />
      ) : (
        <InfoBlockItem name="Company" title={companyTitle} />
      )}
      <InfoBlockItem name="Source" title={source} />
      <InfoBlockItem name="Report Type" title={report.reportType} />
    </>
  );
};

export default InfoBlock;
