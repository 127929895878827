import axios, { AxiosRequestConfig } from "axios";
import { getIdp, getRefreshToken } from "../auth/auth";
import { getSettings } from "../variables";
import { TokenOperationResponse } from "./authApi.types";
import { ApiResponse } from "./axiosHelper.types";

export const InlineCodeExchangePath = "sso/auth/inlineCodeExchange";
export const InlineRefreshPath = "sso/auth/inlineRefresh";

async function requestToken(code: string, idp: string): Promise<ApiResponse<TokenOperationResponse>> {
  const settings = getSettings();
  const { data } = await axios.post<ApiResponse<TokenOperationResponse>>(
    `${settings.PORTAL_BACKEND_BASE_URI}/${InlineCodeExchangePath}`,
    {
      clientId: idp === "Microsoft" ? settings.MICROSOFT_CLIENT_ID : settings.GOOGLE_CLIENT_ID,
      RedirectUri: settings.PORTAL_REDIRECT_URI,
      AuthCode: code,
      IdpType: idp,
    },
    { withCredentials: true }
  );
  return data;
}

async function refreshToken(): Promise<ApiResponse<TokenOperationResponse>> {
  const settings = getSettings();
  const token = getRefreshToken();
  const idp = getIdp();

  const { data } = await axios.post<ApiResponse<TokenOperationResponse>>(
    `${settings.PORTAL_BACKEND_BASE_URI}/${InlineRefreshPath}`,
    {
      clientId: idp === "Microsoft" ? settings.MICROSOFT_CLIENT_ID : settings.GOOGLE_CLIENT_ID,
      refreshToken: token,
      idpType: idp,
    },
    { skipAuthRefresh: true } as AxiosRequestConfig
  );

  return data;
}

export const sso = { requestToken, refreshToken };
