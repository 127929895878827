import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logError } from "../../../logging";
import { selectIsExecutingExcelRun, setEditMode } from "../../../store/workbookSlice";

const isCellInEditModeException = (error: unknown) =>
  error instanceof OfficeExtension.Error && error.code === Excel.ErrorCodes.invalidOperationInCellEditMode;

const checkCellInEditModeDelay = 1000;

const useCellInEditMode = () => {
  const dispatch = useDispatch();
  const isExecutingExcelRun = useSelector(selectIsExecutingExcelRun);

  useEffect(() => {
    if (isExecutingExcelRun === false) {
      return;
    }

    let interval: NodeJS.Timeout | undefined = undefined;
    let isRunning = false;

    const pingCellEditMode = async () => {
      interval = setInterval(async () => {
        try {
          if (isRunning) {
            return;
          }
          isRunning = true;
          await Excel.run(async () => {
            // Checks if the workbook is in cell-edit mode
          });
          dispatch(setEditMode(false));
        } catch (error) {
          if (isCellInEditModeException(error)) {
            dispatch(setEditMode(true));
          } else {
            logError(error, "useCellInEditModeNotification");
          }
        } finally {
          isRunning = false;
        }
      }, checkCellInEditModeDelay);
    };
    pingCellEditMode();

    return () => {
      if (interval) {
        clearInterval(interval);
        dispatch(setEditMode(false));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExecutingExcelRun]);
};

export default useCellInEditMode;
